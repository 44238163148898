import {
  logEvent,
  EVENT_PARAM_TYPE_STRING,
  track,
} from '@artemis/utils/analytics';

const EVENT_ACTION_PRODUCT_DETAIL_PAGE_IMPRESSION =
  'PRODUCT_DETAIL_PAGE_IMPRESSION';
const EVENT_ACTION_ADD_ITEM_TO_CART_CLICK = 'ADD_ITEM_TO_CART_CLICK';

export const logImpression = (merchantId, itemId, authMode) => {
  track(EVENT_ACTION_PRODUCT_DETAIL_PAGE_IMPRESSION, {
    ITEM_ID: itemId,
    MERCHANT_ID: merchantId,
  });
  logEvent(EVENT_ACTION_PRODUCT_DETAIL_PAGE_IMPRESSION, [
    {
      key: 'MERCHANT_ID',
      value: merchantId,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'ITEM_ID',
      value: itemId,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'MERCHANT_AUTH_MODE',
      value: authMode,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ]);
};

export const logAddToCart = (item, merchantId, authMode) => {
  track(EVENT_ACTION_ADD_ITEM_TO_CART_CLICK, {
    ITEM_ID: item.id,
    MERCHANT_ID: merchantId,
  });
  logEvent(EVENT_ACTION_ADD_ITEM_TO_CART_CLICK, [
    {
      key: 'ITEM_CATEGORY',
      value: item.groupTitle,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'ITEM_ID',
      value: item.id,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'ITEM_NAME',
      value: item.title,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'MERCHANT_AUTH_MODE',
      value: authMode,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ]);
};
