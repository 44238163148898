import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import QuantityControls from './QuantityControls';

export const Container = styled.div`
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.25s ease;
  & svg {
    flex-shrink: 0;
    margin-right: ${({ labelPosition }) => labelPosition === 'right' && '12px'};
  }

  &:hover {
    background: ${({ theme }) =>
      theme.palette.hover.onLightBg(theme.palette.background.default)};
  }
`;

const QuantitySelectionBox = ({
  selectedQuantity,
  selectedQuantityPreview,
  minQuantity,
  maxQuantity,
  children,
  className,
  onIncrement,
  disabled,
  labelPosition = 'right',
}) => (
  <Container className={className} labelPosition={labelPosition}>
    {labelPosition === 'left' && children}
    <QuantityControls
      selectedQuantity={selectedQuantity}
      selectedQuantityPreview={selectedQuantityPreview}
      minQuantity={minQuantity}
      maxQuantity={maxQuantity}
      onIncrease={disabled ? null : () => onIncrement(1)}
      onDecrease={
        disabled
          ? null
          : () => {
              if (selectedQuantity - 1 < minQuantity) {
                onIncrement(-selectedQuantity);
              } else {
                onIncrement(-1);
              }
            }
      }
    />
    {labelPosition === 'right' && children}
  </Container>
);

QuantitySelectionBox.propTypes = {
  selectedQuantity: PropTypes.number,
  selectedQuantityPreview: PropTypes.node,
  minQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  children: PropTypes.node,
  onIncrement: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['left', 'right']),
};

export default QuantitySelectionBox;
