import styled from 'styled-components';

export const ChoiceContainer = styled.div`
  margin-bottom: 32px;
`;

export const OptionsContainer = styled.div`
  & > :not(:last-child) {
    margin-bottom: 12px;
  }
  .react-loading-skeleton ~ .react-loading-skeleton {
    margin-top: 10px;
  }
`;
