import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import { Minus, Plus } from './icons';

const Quantity = styled.span`
  margin: 0 8px;
`;

const IconButton = styled.button`
  display: flex;
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.38;
    cursor: default;
  }
`;

const QuantityControls = ({
  selectedQuantity,
  selectedQuantityPreview,
  minQuantity,
  maxQuantity,
  onIncrease,
  onDecrease,
}) => {
  const theme = useContext(ThemeContext);
  const decreaseQuantityLabel = useFormatMessage({
    entry: 'menu.decreaseQuantityButton',
  });
  const increaseQuantityLabel = useFormatMessage({
    entry: 'menu.increaseQuantityButton',
  });

  const canIncrease =
    onIncrease &&
    typeof minQuantity === 'number' &&
    selectedQuantity < maxQuantity;

  const canDecrease = onDecrease && typeof minQuantity === 'number';

  return (
    <>
      {selectedQuantity > 0 && (
        <>
          <IconButton
            type="button"
            aria-label={decreaseQuantityLabel}
            onClick={() => onDecrease()}
            disabled={!canDecrease}
          >
            <Minus color={theme.palette.primary} />
          </IconButton>
          <Quantity>{selectedQuantityPreview}</Quantity>
        </>
      )}
      <IconButton
        type="button"
        aria-label={increaseQuantityLabel}
        onClick={() => onIncrease()}
        disabled={!canIncrease}
      >
        <Plus color={theme.palette.primary} />
      </IconButton>
    </>
  );
};

QuantityControls.propTypes = {
  selectedQuantity: PropTypes.number,
  selectedQuantityPreview: PropTypes.node,
  minQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
};

export default QuantityControls;
