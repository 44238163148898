import React from 'react';
import PropTypes from 'prop-types';
import ItemSelection from '@artemis/components/ItemSelection';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';

import 'react-loading-skeleton/dist/skeleton.css';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import { ChoiceShape } from '../MenuGroup/propTypes';
import ChoiceHeading from './ChoiceHeading';
import { ChoiceContainer, OptionsContainer } from './shared';
import { CHECKBOX, QUANTITY, RADIO } from '../SelectionBox';

const ItemChoice = ({
  choice,
  onSelectOption,
  isSubChoice = false,
  parentChoiceIds = [],
  parentOptionIds = [],
}) => {
  const { getBoolean } = useRemoteConfig();
  const isCaloriesEnabled = getBoolean('rw_menu_calories_enabled');
  const isOptionQuantityEnabled = getBoolean('rw_menu_option_quantity_enabled');

  const isExactlyOneOptionRequired =
    choice.minSelectable === 1 && choice.maxSelectable === 1;
  const maxSelected =
    !isExactlyOneOptionRequired &&
    choice.maxSelectable &&
    choice.numSelected >= choice.maxSelectable;
  const maxUniqueSelected =
    !isExactlyOneOptionRequired &&
    choice.maxUniqueSelectable &&
    choice.numUniqueSelected >= choice.maxUniqueSelectable;

  const choiceHasRemainingFreeOptions =
    choice.freeQuantity > 0 && choice.numSelected < choice.freeQuantity;

  // Note that freeSpendMicro and freeQuantity should never both be non-0.
  // But if they are, we show freeQuantity and hide freeSpendMicro.
  const choiceHasFreeSpendMicro =
    (choice.freeSpendMicro || 0) > 0 && (choice.freeQuantity || 0) === 0;

  return (
    <>
      <ChoiceContainer>
        <ChoiceHeading choice={choice} />
        <OptionsContainer>
          {choice.options.map(option => {
            // Figure out type based on min/max selectable
            let type = isExactlyOneOptionRequired ? RADIO : CHECKBOX;
            if (isOptionQuantityEnabled && option.maxIncrementCount > 1) {
              type = QUANTITY;
            }

            // Limit number selectable based on option's limits, and how many options are allowed for the choice
            const maxIncrementCount = maxSelected
              ? option.incrementCount
              : option.maxIncrementCount;

            const selectedIncrementCount = option.incrementCount || 0;

            const optionHasRemainingFreeQuantity =
              selectedIncrementCount < option.freeQuantity;

            const isFree =
              choiceHasRemainingFreeOptions || optionHasRemainingFreeQuantity;

            // Only show price if the option will cost money.
            // Unless the choice has freeSpendMicro, in which case we always show the option prices.
            const price =
              (option.priceMicro !== 0 && !isFree) || choiceHasFreeSpendMicro
                ? option.price
                : null;

            return (
              <ItemSelection
                name={choice.id}
                key={option.id}
                type={type}
                title={option.title}
                price={price}
                disabled={
                  (!option.selected && (maxSelected || maxUniqueSelected)) ||
                  (type === CHECKBOX &&
                    option.selected &&
                    option.minIncrementCount === 1) ||
                  !option.availability.isAvailable
                }
                disabledReason={
                  !option.availability.isAvailable && (
                    <FormattedMessage entry="menu.out_of_stock" />
                  )
                }
                calorieRange={isCaloriesEnabled ? option.calorieRange : null}
                selected={option.selected}
                onSelectionChange={({ selected, incrementCount }) => {
                  onSelectOption({
                    choiceId: choice.id,
                    optionId: option.id,
                    isSubChoice,
                    parentChoiceIds,
                    parentOptionIds,
                    selected,
                    incrementCount,
                  });
                }}
                selectedIncrementCount={option.incrementCount || 0}
                minIncrementCount={option.minIncrementCount}
                maxIncrementCount={maxIncrementCount}
                incrementDenominator={option.incrementDenominator}
                incrementNumerator={option.incrementNumerator}
              />
            );
          })}
        </OptionsContainer>
      </ChoiceContainer>
      {choice.options
        .filter(option => option.selected)
        .map(option =>
          option?.subChoices.map(subChoice => (
            <ItemChoice
              key={subChoice.id}
              choice={subChoice}
              onSelectOption={onSelectOption}
              isSubChoice
              parentChoiceIds={[...parentChoiceIds, choice.id]}
              parentOptionIds={[...parentOptionIds, option.id]}
            />
          )),
        )}
    </>
  );
};

ItemChoice.propTypes = {
  choice: ChoiceShape.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  isSubChoice: PropTypes.bool,
  parentChoiceIds: PropTypes.arrayOf(PropTypes.string),
  parentOptionIds: PropTypes.arrayOf(PropTypes.string),
};

export default ItemChoice;
