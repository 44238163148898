import React from 'react';
import styled from 'styled-components';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import theme from '@artemis/theme';
import { formatCurrency } from '@artemis/utils/currency-format';
import { ChoiceShape } from '../MenuGroup/propTypes';

const Container = styled.div`
  margin-bottom: 18px;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const Subheading = styled.div`
  ${props => props.theme.typography.caption};
`;

const Note = styled.span`
  ${props => props.theme.typography.caption};
  text-align: right;
  text-transform: uppercase;
  color: ${props =>
    props.$error ? props.theme.rtColors.red700 : props.theme.palette.grey[600]};
  display: flex;
  align-items: center;
`;

const TitleLine = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const Divider = styled.span`
  ${props => props.theme.typography.caption};
`;

// How many options must be selected
const getRangeText = (min, max) => {
  if (min && max) {
    if (min === max) {
      return (
        <FormattedMessage
          key="subheading-range"
          entry="menu.choices.subheading.exact"
          values={{ count: min }}
        />
      );
    }
    return (
      <FormattedMessage
        key="subheading-range"
        entry="menu.choices.subheading.range"
        values={{ min, max }}
      />
    );
  }

  if (min) {
    return (
      <FormattedMessage
        key="subheading-range"
        entry="menu.choices.subheading.min"
        values={{ min }}
      />
    );
  }

  if (max) {
    return (
      <FormattedMessage
        key="subheading-range"
        entry="menu.choices.subheading.max"
        values={{ max }}
      />
    );
  }

  return null;
};

// How many options are included for free
const getIncludedText = included => {
  if (included > 0) {
    return (
      <FormattedMessage
        key="subheading-included"
        entry="menu.choices.subheading.included_quantity"
        values={{ quantity: included }}
      />
    );
  }

  return null;
};

// Dollar amount of options that are included for free.
// Note that includedMicro and includedQuantity should never both be non-0.
// But if they are, we show inlcudedQuantity and hide includedMicro.
const getIncludedDollarsText = (includedMicro, includedQuantity) => {
  if (includedMicro > 0 && includedQuantity === 0) {
    return (
      <FormattedMessage
        key="subheading-included"
        entry="menu.choices.subheading.included_dollars"
        values={{ dollars: formatCurrency({ value: includedMicro }) }}
      />
    );
  }

  return null;
};

// How many options the user selected, beyond what's included
const getAddedText = (included, total) => {
  const added = total - included;
  if (included > 0 && added > 0) {
    return (
      <FormattedMessage
        key="subheading-added"
        entry="menu.choices.subheading.added_quantity"
        values={{ quantity: total - included }}
      />
    );
  }

  return null;
};

// Dollar amount of selected options, beyond what's included.
// Note that includedMicro and includedQuantity should never both be non-0.
// But if they are, we show inlcudedQuantity/addedQuantity and hide includedMicro/extraMicro.
const getExtraDollarsText = (includedMicro, totalMicro, includedQuantity) => {
  const extraMicro = totalMicro - includedMicro;
  if (includedMicro > 0 && extraMicro > 0 && includedQuantity === 0) {
    return (
      <FormattedMessage
        key="subheading-added"
        entry="menu.choices.subheading.extra_dollars"
        values={{ dollars: formatCurrency({ value: extraMicro }) }}
      />
    );
  }

  return null;
};

const getSubheadingText = ({
  min,
  max,
  included,
  includedMicro,
  total,
  totalMicro,
}) => {
  const result = [];

  const parts = [
    getRangeText(min, max),
    getIncludedText(included),
    getAddedText(included, total),
    getIncludedDollarsText(includedMicro, included),
    getExtraDollarsText(includedMicro, totalMicro, included),
  ].filter(val => !!val);

  // Add a divider in between each part
  parts.forEach((part, index) => {
    result.push(part);
    if (index < parts.length - 1) {
      // eslint-disable-next-line react/no-array-index-key
      result.push(<Divider key={`divider-${index}`}> • </Divider>);
    }
  });

  return result;
};

const ChoiceHeading = ({ choice }) => {
  const isRequired = choice.minSelectable > 0 || choice.minUniqueSelectable > 0;

  const subheadingText = getSubheadingText({
    min: choice.minSelectable,
    max: choice.maxSelectable,
    included: choice.freeQuantity || 0,
    includedMicro: choice.freeSpendMicro || 0,
    total: choice.numSelected,
    totalMicro: choice.selectedTotalPriceMicro || 0,
  });
  const showError = choice.needsMoreSelected;

  return (
    <Container>
      <TitleLine>
        <Title>{choice.title}</Title>
        <Note $error={showError}>
          {isRequired && showError && (
            <ErrorOutlineIcon
              sx={{ fontSize: '16px', color: theme.rtColors.red700, mr: '4px' }}
            />
          )}
          {isRequired && <FormattedMessage entry="menu.choices.required" />}
        </Note>
      </TitleLine>
      {subheadingText && <Subheading>{subheadingText}</Subheading>}
    </Container>
  );
};

ChoiceHeading.propTypes = {
  choice: ChoiceShape.isRequired,
};

export default ChoiceHeading;
