/**
 *
 * QuantitySelector
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const Sign = styled.button`
  background-color: ${props => props.theme.palette.grey[200]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 48px;
  width: 48px;
  padding: 0;
  cursor: pointer;
  user-select: none;
  border: none;
  transition: 0.25s background-color ease;
  ${({ theme }) => theme.isTablet`
    height: 38px;
    width: 38px;
  `};

  ${props =>
    props.error &&
    `
      opacity: 25%;
    `};

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.hover.onLightBg(theme.palette.grey[200])};
  }
`;

const Quantity = styled.div``;

/**
 * Component for Quantity Selector
 */
const QuantitySelector = ({ onDecrease, onIncrease, quantity, error }) => (
  <Container>
    <Sign onClick={onDecrease} data-testid="minus">
      -
    </Sign>
    <Quantity>{quantity}</Quantity>
    <Sign onClick={onIncrease} data-testid="plus" error={error}>
      +
    </Sign>
  </Container>
);

QuantitySelector.propTypes = {
  onDecrease: PropTypes.func,
  onIncrease: PropTypes.func,
  quantity: PropTypes.number,
  error: PropTypes.bool,
};

QuantitySelector.defaultProps = {
  quantity: 0,
};

export default QuantitySelector;
