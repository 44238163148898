/**
 *
 * ItemSelection
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BaseSelectionBox, {
  RADIO,
  CHECKBOX,
  QUANTITY,
} from '@artemis/components/SelectionBox';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { CalorieRangeShape } from '../MenuGroup/propTypes';
import CalorieRange from '../MenuGroup/CalorieRange';
import QuantitySelectionBox from '../SelectionBox/QuantitySelectionBox';
import { formatIncrement } from './utils';

const selectionBoxCSS = css`
  padding: 16px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  background-color: ${props =>
    props.disabled
      ? props.theme.palette.grey[100]
      : props.theme.palette.background.card};
  box-shadow: ${props => props.theme.shadows.shadow0};
  border-radius: 4pt;
  ${props => {
    if (props.focused) {
      return `border: 2px solid ${props.theme.palette.secondary}`;
    }
    return props.selected
      ? `border: 2px solid ${props.theme.palette.primary}`
      : `border: 2px solid ${props.theme.palette.background.card}`;
  }}
`;

const SelectionBox = styled(BaseSelectionBox)`
  ${selectionBoxCSS}
`;

const StyledQuantitySelectionBox = styled(QuantitySelectionBox)`
  ${selectionBoxCSS}
`;

const Container = styled.div`
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemTitle = styled.div`
  ${props => props.theme.typography.bodySmall};
`;

const Divider = styled.span`
  ${props => props.theme.typography.caption};
`;

const ItemPrice = styled.div`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.palette.text.regular.hint};
  margin-left: 6px;
  margin-right: 6px;
  white-space: nowrap;
`;

const DisabledReason = styled.span`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.palette.text.regular.hint};
  display: block;
`;

const StyledCalorieRange = styled(CalorieRange)`
  margin-left: 6px;
  ${props => props.theme.typography.caption};
  white-space: nowrap;
`;

/**
 * ItemSelection component description
 */
const ItemSelection = ({
  onSelectionChange,
  onChange,
  selected,
  type,
  title,
  price,
  calorieRange,
  className,
  disabledReason,
  disabled,
  selectedIncrementCount,
  minIncrementCount,
  maxIncrementCount,
  incrementDenominator,
  incrementNumerator,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  const content = (
    <Container disabled={disabled}>
      <TitleContainer>
        <ItemTitle>{title}</ItemTitle>
        {price && (
          <ItemPrice>
            {type === QUANTITY ? (
              <FormattedMessage
                entry="menu.option.price_per_increment"
                values={{ price }}
              />
            ) : (
              price
            )}
          </ItemPrice>
        )}
        {price && calorieRange && <Divider>•</Divider>}
        {calorieRange && <StyledCalorieRange range={calorieRange} />}
      </TitleContainer>
      {disabledReason && <DisabledReason>{disabledReason}</DisabledReason>}
    </Container>
  );

  if (type === QUANTITY) {
    return (
      <StyledQuantitySelectionBox
        selected={selected}
        selectedQuantity={selectedIncrementCount}
        selectedQuantityPreview={formatIncrement({
          incrementCount: selectedIncrementCount,
          incrementDenominator,
          incrementNumerator,
        })}
        minQuantity={minIncrementCount}
        maxQuantity={maxIncrementCount}
        onIncrement={incrementChange => {
          const newIncrementCount = selectedIncrementCount + incrementChange;
          onSelectionChange({
            selected: newIncrementCount > 0,
            incrementCount: newIncrementCount,
          });
        }}
        disabled={disabled}
        labelPosition="left"
      >
        {content}
      </StyledQuantitySelectionBox>
    );
  }

  return (
    <SelectionBox
      className={className}
      type={type}
      onClick={e => {
        // Clicking the option toggles it on or off
        e.preventDefault();
        if (!disabled) {
          const newSelected = !selected;
          onSelectionChange({
            selected: newSelected,
            incrementCount: newSelected ? 1 : 0,
          });
        }
      }}
      onChange={onChange}
      selected={selected}
      disabled={disabled}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      focused={focused}
      labelPosition="left"
      {...rest}
    >
      {content}
    </SelectionBox>
  );
};

ItemSelection.propTypes = {
  /**
   * Override styling
   */
  className: PropTypes.string,
  /**
   * SelectionBox type, either radio or checkbox
   */
  type: PropTypes.oneOf([QUANTITY, RADIO, CHECKBOX]),
  /**
   * State of SelectionBox
   */
  selected: PropTypes.bool,
  /**
   * Should the use be able to interact with this box
   */
  disabled: PropTypes.bool,
  /**
   * Called when the option is toggled, or the selected quantity changes
   */
  onSelectionChange: PropTypes.func,
  /**
   * on change function for input
   */
  onChange: PropTypes.func,
  /**
   * Item title
   */
  title: PropTypes.string,
  /**
   * Children represents inside SelectionBox
   */
  disabledReason: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  /**
   * Item price
   */
  price: PropTypes.string,
  calorieRange: CalorieRangeShape,
  selectedIncrementCount: PropTypes.number,
  minIncrementCount: PropTypes.number,
  maxIncrementCount: PropTypes.number,
  incrementDenominator: PropTypes.number,
  incrementNumerator: PropTypes.number,
};

ItemSelection.defaultProps = {
  className: '',
  type: RADIO,
  selected: false,
  title: '',
  price: '',
};

export default ItemSelection;
