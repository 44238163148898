import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';
import MenuItemModal from '@artemis/components/MenuItemModal';
import {
  getActiveMenuItemWithChoices,
  getIsActiveMenuItemLoading,
  getIsExpressServiceModalOpen,
} from '@artemis/store/menu/selectors';
import {
  getMerchantId,
  getMerchantAuthMode,
} from '@artemis/store/merchant/selectors';
import { closeItem } from '@artemis/store/menu/slice';
import { addToCart, modifyItemInCart } from '@artemis/store/cart/slice';
import { getItemUpdating } from '@artemis/store/cart/selectors';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import { MENU_DEBUG_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import {
  getSelectedOptionsCount,
  getUniqueSelectedOptionsCount,
  getSelectedOptionsTotalPriceMicro,
  getNeedsMoreSelected,
} from '@artemis/components/ItemSelection/utils';
import { logImpression, logAddToCart } from './analytics';

/* eslint-disable no-param-reassign */
const updateOptionSelection = (
  choice,
  selectedOption,
  selected,
  incrementCount,
) => {
  // If the choice requires exactly one option unselect all the other options
  if (choice.maxSelectable === 1 && choice.minSelectable === 1) {
    if (selected) {
      choice.options.forEach(option => {
        option.selected = false;
        option.incrementCount = 0;
      });
      selectedOption.selected = true;
      selectedOption.incrementCount = 1;
    }
    // If user is trying to unselect the option, do nothing (it is required)
  } else {
    selectedOption.selected = selected;
    selectedOption.incrementCount = incrementCount;
  }
  choice.numSelected = getSelectedOptionsCount(choice.options);
  choice.numUniqueSelected = getUniqueSelectedOptionsCount(choice.options);
  choice.selectedTotalPriceMicro = getSelectedOptionsTotalPriceMicro(
    choice.options,
  );
  choice.needsMoreSelected = getNeedsMoreSelected(choice.options, choice);
};

const MenuItemModalContainer = () => {
  const dispatch = useDispatch();
  const { authenticated, initialized } = useContext(AuthenticationContext);
  const { getBoolean } = useRemoteConfig();
  const isLocalSelectionsEnabled = getBoolean('rw_local_selections_enabled');
  const merchantId = useSelector(getMerchantId);
  const activeMenuItem = useSelector(getActiveMenuItemWithChoices);
  const isLoading = useSelector(getItemUpdating);
  const isLoadingChoices = useSelector(getIsActiveMenuItemLoading);
  const isExpressServiceModalOpen = useSelector(getIsExpressServiceModalOpen);
  const isDebugMode = useQueryCheck(MENU_DEBUG_PARAM);
  const [cartItem, setCartItem] = useState(activeMenuItem);

  const readyTime = useFormatMessage({
    entry: 'menu.itemModal.readyTime',
    values: {
      minute: cartItem
        ? Math.floor(cartItem.estimatedPrepTimeMillis / 36000)
        : '',
    },
  });
  const merchantAuthMode = useSelector(getMerchantAuthMode);
  const isMenuItemModalOpen = !isExpressServiceModalOpen && !!activeMenuItem;

  useEffect(() => {
    if (activeMenuItem) {
      setCartItem({
        ...activeMenuItem,
        quantity: activeMenuItem.quantity || 1,
      });
      logImpression(merchantId, activeMenuItem.id, merchantAuthMode);
    }
  }, [activeMenuItem]);

  const closeModal = useCallback(() => {
    if (!isLoading) dispatch(closeItem());
  }, [isLoading, closeItem, dispatch]);

  const handleQuantityIncrease = () => {
    const currentQuantity = cartItem.quantity;
    if (
      !cartItem.maxAmountPerOrder ||
      currentQuantity < cartItem.maxAmountPerOrder
    ) {
      setCartItem({
        ...cartItem,
        quantity: cartItem.quantity + 1,
      });
    }
  };
  const handleQuantityDecrease = () => {
    const currentQuantity = cartItem.quantity;
    if (currentQuantity > 1) {
      setCartItem({
        ...cartItem,
        quantity: cartItem.quantity - 1,
      });
    }
  };
  const handleOptionSelect = ({
    optionId,
    choiceId,
    isSubChoice = false,
    parentOptionIds = [],
    parentChoiceIds = [],
    selected,
    incrementCount,
  }) => {
    const newItem = produce(cartItem, draft => {
      let selectedChoice;
      let selectedOption;
      if (isSubChoice) {
        for (let layer = 0; layer < parentOptionIds.length; layer += 1) {
          if (layer === 0) {
            selectedChoice = draft.choices.find(
              choice => choice.id === parentChoiceIds[layer],
            );
            selectedOption = selectedChoice.options.find(
              option => option.id === parentOptionIds[layer],
            );
          } else {
            selectedChoice = selectedOption.subChoices.find(
              choice => choice.id === parentChoiceIds[layer],
            );
            selectedOption = selectedChoice.options.find(
              option => option.id === parentOptionIds[layer],
            );
          }
        }
        selectedChoice = selectedOption.subChoices.find(
          choice => choice.id === choiceId,
        );
      } else {
        selectedChoice = draft.choices.find(choice => choice.id === choiceId);
      }
      selectedOption = selectedChoice.options.find(
        option => option.id === optionId,
      );
      updateOptionSelection(
        selectedChoice,
        selectedOption,
        selected,
        incrementCount,
      );
    });
    setCartItem(newItem);
  };
  const handleUpdateNote = e => {
    setCartItem({
      ...cartItem,
      note: e.target.value,
    });
  };
  const handleTogglePerk = isSelected => {
    setCartItem({
      ...cartItem,
      perk: {
        ...cartItem.perk,
        isSelected,
      },
    });
  };
  const addOptionsToList = (optionList, choice) => {
    let options = [];
    choice.options
      .filter(option => option.selected)
      .forEach(option => {
        let subOptions = [
          {
            id: option.id,
            incrementCount: option.incrementCount || 1,
          },
        ];
        if (option.subChoices.length > 0) {
          subOptions = option.subChoices.reduce(
            (subOptionsList, subChoice) =>
              addOptionsToList(subOptionsList, subChoice),
            subOptions,
          );
        }
        options = options.concat(subOptions);
      });
    return optionList.concat(options);
  };
  const handleAddToCart = () => {
    const selectedOptions = cartItem.choices.reduce(
      (optionList, choice) => addOptionsToList(optionList, choice),
      [],
    );
    const action = !cartItem.cartItemId ? addToCart : modifyItemInCart;
    logAddToCart(cartItem, merchantId, merchantAuthMode);
    dispatch(
      action({
        merchantId,
        cartItemId: cartItem.cartItemId,
        item: {
          menuItemId: cartItem.id,
          quantity: cartItem.quantity,
          note: cartItem.note,
          cartItemOptions: selectedOptions,
          title: cartItem.title,
          priceMicro: cartItem.priceMicro,
          perkId: cartItem?.perk?.isSelected
            ? cartItem.perk?.perkId
            : undefined,
        },
        isGuestUser: initialized && !authenticated,
        isLocalSelectionsEnabled,
      }),
    );
  };
  if (!cartItem) {
    return null;
  }
  return (
    <MenuItemModal
      onAddToCart={handleAddToCart}
      onSelectOption={handleOptionSelect}
      onQuantityIncrease={handleQuantityIncrease}
      onQuantityDecrease={handleQuantityDecrease}
      onChangeNote={handleUpdateNote}
      onTogglePerk={handleTogglePerk}
      onRequestClose={closeModal}
      onCloseModal={closeModal}
      onModify={handleAddToCart}
      merchantId={merchantId}
      item={{
        ...cartItem,
        readyTime,
      }}
      isOpen={isMenuItemModalOpen}
      isLoading={isLoading}
      isLoadingChoices={isLoadingChoices}
      isDebugMode={isDebugMode}
    />
  );
};

export default MenuItemModalContainer;
