import React from 'react';
import { RtToggle } from '@ritualco/ritual-frontend-components';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import AvailabilityHours from '@artemis/components/AvailabilityHours';
import Loading from '@artemis/components/Loading';
import CustomModal from '@artemis/components/Modal';
import QuantitySelector from '@artemis/components/QuantitySelector';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';

import 'react-loading-skeleton/dist/skeleton.css';
import { CalorieRangeShape, ChoiceShape } from '../MenuGroup/propTypes';
import { ChoiceContainer, OptionsContainer } from './shared';
import ItemChoice from './ItemChoice';

const NOTE_MAX_LENGTH = 160; // based on limit in database

const MODAL_WIDTH_DESKTOP = 460;

const Modal = styled(CustomModal)`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.background.card};
  :focus {
    outline: none;
  }
  ${({ theme }) => theme.isTablet`
    border-radius: 4pt;
    width: ${MODAL_WIDTH_DESKTOP}px;
    max-height: 700px;
    margin: 0 auto;
    margin: 50vh auto;
    transform: translateY(-50%);
  `}
`;

const Image = styled.div`
  position: relative;
`;

const ItemImage = styled.div`
  height: 240px;
  width: 100%;
  margin: 0 auto;
  background: url(${props => props.img}) no-repeat center;
  background-size: cover;
`;

const CloseModal = styled.button`
  z-index: 2;
  background: ${props => props.theme.palette.common.white};
  box-shadow: ${props => props.theme.shadows.shadow1};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  top: 24px;
  left: 24px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  position: absolute;
`;

const CloseIcon = styled(ResponsiveImage)`
  height: 24px;
  width: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme }) => theme.isTablet`
    border-radius: 4pt;
  `}
`;
const ItemContainer = styled.div`
  padding: 24px 24px 0;
  ${({ image }) => !image && `padding-top: 70px;`}
`;
const FlexMobilePadding = styled.div`
  min-height: 202px;
  ${({ theme }) => theme.isTablet`
    min-height: 184px;
  `};
`;

const ItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

const ItemName = styled.div`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const ItemDescription = styled.div`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.palette.text.regular.hint};
  margin-bottom: 12px;
  text-align: left;
`;

const ChoiceName = styled.div`
  margin-bottom: 8px;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const Checkout = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.palette.background.card};
  box-shadow: ${props => props.theme.shadows.shadow2};
  padding: 16px 16px 40px;
  z-index: 1;
  ${({ theme }) => theme.isTablet`
    border-bottom-left-radius: 4pt;
    border-bottom-right-radius: 4pt;
    padding: 24px;
  `};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const ErrorMessage = styled.div`
  padding-bottom: 16px;
  background-color: ${props => props.theme.palette.background.card};
  color: ${props => props.theme.palette.red[600]};
  ${props => props.theme.typography.bodySmall};
`;

const AddToCartButton = styled.button`
  background-color: ${props => props.theme.palette.primary};
  color: ${props => props.theme.palette.common.white};
  border: none;
  ${props => props.theme.typography.button};
  flex-grow: 1;
  border-radius: 4pt;
  margin-left: 16px;
  padding: 0;
  cursor: pointer;
  transition: 0.25s background-color ease;
  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.hover.onLightBg(theme.palette.primary)};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: ${({ theme }) => theme.palette.action.disabled};
  }
`;

const PerkContainer = styled.div`
  padding-bottom: 16px;
  ${props => props.theme.typography.bodySmall};
  display: flex;
  justify-content: space-between;
`;

const PerkPrimary = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.palette.primary};
`;

const PerkSecondary = styled.p`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.palette.text.regular.hint};
`;

const NoteContainer = styled.div`
  padding: 0 26px 26px;
`;

const NoteLabel = styled.label`
  ${props => props.theme.typography.bodySmall};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const Note = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.palette.grey[100]};
  border: 1px solid ${props => props.theme.palette.grey[200]};
  padding: 8px;
  border-radius: 4pt;
  margin-top: 8px;
  ${props => props.theme.typography.body};
  resize: none;
`;

const MenuItemModal = ({
  isOpen = false,
  isLoading = false,
  isLoadingChoices = false,
  item,
  onQuantityIncrease,
  onQuantityDecrease,
  onAddToCart,
  onModify,
  onCloseModal,
  onSelectOption,
  onChangeNote,
  onTogglePerk,
  merchantId,
  isDebugMode = false,
  ...rest
}) => {
  let buttonText = 'menu.out_of_stock';
  if (item.availability.isAvailable) {
    buttonText = item.cartItemId ? 'menu.modify_item' : 'menu.add_to_cart';
  }
  const maxItemsReached = item.quantity === item.maxAmountPerOrder;
  return (
    <Modal isOpen={isOpen} ariaHideApp={false} {...rest}>
      <CloseModal data-testid="close-menu-item" onClick={onCloseModal}>
        <CloseIcon id="close.svg.img" />
      </CloseModal>
      <Container>
        <Image>
          {item.imageUrl && (
            <ItemImage
              img={`${item.imageUrl}${
                /* Only googleusercontent supports resizing with suffixes */
                item.imageUrl.includes('googleusercontent.com')
                  ? `=w${MODAL_WIDTH_DESKTOP}`
                  : ''
              }`}
            />
          )}
        </Image>
        <ItemContainer image={!!item.imageUrl}>
          <ItemDetail>
            <ItemName>{item.title}</ItemName>
            {isDebugMode && (
              <AvailabilityHours
                availabilityHours={item.dayAvailability}
                centered
              />
            )}
            <ItemDescription>{item.description}</ItemDescription>
          </ItemDetail>
          {isLoadingChoices
            ? item?.choiceIds?.map(choiceId => (
                <ChoiceContainer key={choiceId}>
                  <ChoiceName>
                    <Skeleton height={24} width={120} />
                  </ChoiceName>
                  <OptionsContainer>
                    <Skeleton count={3} height={57} />
                  </OptionsContainer>
                </ChoiceContainer>
              ))
            : item?.choices?.map(choice => (
                <ItemChoice
                  key={choice.id}
                  choice={choice}
                  onSelectOption={onSelectOption}
                  path={[]}
                />
              ))}
        </ItemContainer>
        {item.hasNotesOption !== false && (
          <NoteContainer>
            <NoteLabel>
              <FormattedMessage entry="menu.special_requests" />
            </NoteLabel>
            <Note
              data-testid="special-request"
              value={item.note}
              onChange={onChangeNote}
              maxLength={NOTE_MAX_LENGTH}
            />
          </NoteContainer>
        )}
        <FlexMobilePadding />
        <Checkout>
          {maxItemsReached && (
            <ErrorMessage>
              <FormattedMessage
                entry="menu.maxItemsReached"
                values={{ maxAmountPerOrder: item.maxAmountPerOrder }}
              />
            </ErrorMessage>
          )}
          {item?.perk?.perkId && (
            <PerkContainer>
              <div>
                <PerkPrimary>{item.perk?.primaryMessage}</PerkPrimary>
                <PerkSecondary>{item.perk?.secondaryMessage}</PerkSecondary>
              </div>
              <RtToggle
                isActive={item.perk?.isSelected}
                onClick={() => {
                  onTogglePerk(!item.perk?.isSelected);
                }}
              />
            </PerkContainer>
          )}
          <Buttons>
            <QuantitySelector
              onIncrease={onQuantityIncrease}
              onDecrease={onQuantityDecrease}
              quantity={item.quantity}
              error={maxItemsReached}
            />
            <AddToCartButton
              onClick={item.cartItemId ? onAddToCart : onModify}
              disabled={
                item.choices.some(choice => choice.needsMoreSelected) ||
                !item.availability.isAvailable ||
                isLoadingChoices ||
                isDebugMode
              }
            >
              {useFormatMessage({ entry: buttonText })}
            </AddToCartButton>
          </Buttons>
        </Checkout>
      </Container>
      <Loading isLoading={isLoading} data-testid="menuItemModalLoading" />
    </Modal>
  );
};

const MenuItemPropType = PropTypes.shape({
  id: PropTypes.string,
  cartItemId: PropTypes.string,
  maxAmountPerOrder: PropTypes.number,
  hasNotesOption: PropTypes.bool,
  quantity: PropTypes.number,
  title: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.string,
  readyTime: PropTypes.string,
  imageUrl: PropTypes.string,
  note: PropTypes.string,
  availability: PropTypes.shape({
    isAvailable: PropTypes.bool.isRequired,
  }).isRequired,
  dayAvailability: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfTheWeek: PropTypes.string,
      availability: PropTypes.shape({
        durations: PropTypes.arrayOf(
          PropTypes.shape({
            startTimeMs: PropTypes.number,
            endTimeMs: PropTypes.number,
          }),
        ),
      }),
    }),
  ),
  choiceIds: PropTypes.arrayOf(PropTypes.string),
  choices: PropTypes.arrayOf(ChoiceShape),
  perk: PropTypes.shape({
    perkId: PropTypes.string,
    primaryMessage: PropTypes.string,
    secondaryMessage: PropTypes.string,
    isSelected: PropTypes.bool,
  }),
  calorieRange: CalorieRangeShape,
});

MenuItemModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  item: MenuItemPropType,
  onQuantityIncrease: PropTypes.func,
  onQuantityDecrease: PropTypes.func,
  onAddToCart: PropTypes.func,
  onModify: PropTypes.func,
  onCloseModal: PropTypes.func,
  onSelectOption: PropTypes.func,
  onChangeNote: PropTypes.func,
  onTogglePerk: PropTypes.func,
  merchantId: PropTypes.string,
  isDebugMode: PropTypes.bool,
  isLoadingChoices: PropTypes.bool,
};

export default MenuItemModal;
